export const config = {
	plex: {
		name: 'Amiti PLEX',
		leftMenuTheme: 'm-brand--skin-light',
		logoBlock: 'm-brand--skin-light',
		entitySingle: 'Scheme',
		entityPlural: 'Schemes',
		entitiesUrl: '/plex/entities',
		entityAddUrl: '/plex/entities/add',
		entityEditUrl: '/entities/edit',
		entityLogo: 'assets/plex/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/dashboard',
		dashboardUrl: '/dashboard',
		headerShowTaskNotifications: false,
		managementTeamSchemeExecutiveIcon: 'SX',
		managementTeamSchemeExecutiveText: 'Scheme Executive',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Executive Managing Agent',
		userEditAssociatedProperties: true,
		userEditAssociatedPlayers: false,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: true,
		multiplePrimaries: true,
		userAddAssociateTypeLabel: 'Property',
		managementName: 'Management',
		primaryColor: '#00a7ee',
		faqUrl: 'https://www.help.amiti.online',
		sendgridNewslSupprGrpId: 36535,
	},
	serve: {
		name: 'Amiti Serve',
		leftMenuTheme: 'm-brand--skin-light',
		logoBlock: 'm-brand--skin-light',
		entitySingle: 'Company',
		entityPlural: 'Companies',
		entitiesUrl: '/serve/serve/entities/list',
		entityAddUrl: '/serve/entities/add',
		entityEditUrl: '/entities/edit',
		entityLogo: 'assets/serve/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/serve',
		dashboardUrl: '/serve',
		headerShowTaskNotifications: false,
		managementTeamSchemeExecutiveIcon: 'SX',
		managementTeamSchemeExecutiveText: 'Company Executive',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Company Managing Agent',
		userEditAssociatedProperties: true,
		userEditAssociatedPlayers: false,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: false,
		managementName: 'Management',
		faqUrl: 'https://www.help.amiti.online',
		sendgridNewslSupprGrpId: 36539,
	},
	faith: {
		name: 'Amiti Faith',
		leftMenuTheme: 'm-brand--skin-light',
		logoBlock: 'm-brand--skin-light',
		entitySingle: 'Organisation',
		entityPlural: 'Organisations',
		entitiesUrl: '/faith/faith/organisations',
		entityAddUrl: '/faith/faith/organisations/add',
		entityEditUrl: '/faith/organisations/edit',
		entityLogo: 'assets/faith/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/faith',
		dashboardUrl: '/faith',
		headerShowTaskNotifications: false,
		managementTeamAdditions: [
			{
				profile: 'isExecutiveTeam',
				icon: 'ET',
				text: 'Executive Team',
			},
			{
				profile: 'isPastoralTeam',
				icon: 'PT',
				text: 'Pastoral Team',
			},
			{
				profile: 'isMinistryTeam',
				icon: 'MT',
				text: 'Ministry Team',
			},
			{
				profile: 'isAdminTeam',
				icon: 'AT',
				text: 'Admin Team',
			},
		],
		userEditAssociatedProperties: false,
		userEditAssociatedMembers: true,
		userEditAssociatedGroups: true,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: false,
		userAddAssociateTypeLabel: 'Member',
		managementName: 'Our Team',
		primaryColor: '#ee2b7b',
		faqUrl: 'https://www.help.amiti.online',
		sendgridNewslSupprGrpId: 36381,
	},
	connect: {
		name: 'Amiti Connect',
		leftMenuTheme: 'm-brand--skin-light',
		logoBlock: 'm-brand--skin-light',
		entitySingle: 'Scheme',
		entityPlural: 'Schemes',
		entitiesUrl: '/entities',
		entityAddUrl: '/entities/add',
		entityEditUrl: '/entities/edit',
		entityLogo: 'assets/connect/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/connect',
		dashboardUrl: '/connect',
		headerShowTaskNotifications: false,
		managementTeamSchemeExecutiveIcon: 'SX',
		managementTeamSchemeExecutiveText: 'Scheme Executive',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Executive Managing Agent',
		userEditAssociatedProperties: true,
		userEditAssociatedPlayers: false,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: false,
		managementName: 'Management',
		faqUrl: 'https://www.help.amiti.online',
		sendgridNewslSupprGrpId: 36540,
	},
	sports: {
		name: 'Amiti Sports',
		logoBlock: 'm-brand--skin-light',
		leftMenuTheme: 'm-brand--skin-light',
		entitySingle: 'Club',
		entityPlural: 'Clubs',
		entitiesUrl: '/sports/sports/entities',
		entityAddUrl: '/sports/sports/entities/add',
		entityEditUrl: '/sports/entities/edit',
		entityLogo: 'assets/sports/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'club_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/sports/dashboard',
		dashboardUrl: '/sports/dashboard',
		headerShowTaskNotifications: false,
		managementTeamSchemeExecutiveIcon: 'MT',
		managementTeamSchemeExecutiveText: 'Management Team',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Executive Managing Agent',
		userEditAssociatedProperties: false,
		userEditAssociatedPlayers: true,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: false,
		userAddAssociateTypeLabel: 'Player',
		managementName: 'Management',
		primaryColor: '#0fa14a',
		faqUrl: 'https://amiti.cloud/sports/faq/',
		sendgridNewslSupprGrpId: 36541,
	},
	whitfields: {
		name: 'Whitfields',
		leftMenuTheme: 'm-brand--skin-dark',
		logoBlock: 'm-brand--skin-dark',
		entitySingle: 'Scheme',
		entityPlural: 'Schemes',
		entitiesUrl: '/whitfields/management/entities',
		entityAddUrl: '/whitfields/entities/add',
		entityEditUrl: '/entities/edit',
		entityLogo: 'assets/whitfields/logo.png',
		entityColour2: true,
		entityColour3: true,
		entityAddShowSelectProvince: true,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerQuickLinksDashboardUrl: '/management/db',
		dashboardUrl: '/management/db',
		headerShowManagement: true,
		headerShowQuickLinks: true,
		headerShowTaskNotifications: true,
		managementTeamSchemeExecutiveIcon: 'SX',
		managementTeamSchemeManagerIcon: 'SM',
		managementTeamSchemeExecutiveText: 'Scheme Executive',
		managementTeamSchemeManagerText: 'Scheme Manager',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Executive Managing Agent',
		userEditAssociatedProperties: true,
		userEditAssociatedPlayers: false,
		showFFPropertyExtraFields: true,
		showSponsorExtraAttorneyFields: true,
		serviceProvidersNewsletterAdvertiser: true,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: true,
		multipleAlternates: true,
		multiplePrimaries: false,
		userAddAssociateTypeLabel: 'Property',
		managementName: 'Executives',
		primaryColor: '#007087',
		faqUrl: 'https://www.help.amiti.online',
		sendgridNewslSupprGrpId: 36879,
	},
	olympus: {
		name: 'Olympus',
		leftMenuTheme: 'm-brand--skin-light',
		logoBlock: 'm-brand--skin-light',
		entitySingle: 'Scheme',
		entityPlural: 'Schemes',
		entitiesUrl: '/olympus/dashboard',
		entityAddUrl: '/entities/add',
		entityEditUrl: '/entities/edit',
		entityLogo: 'assets/olympus/logo.png',
		entityColour2: false,
		entityColour3: false,
		entityAddShowSelectProvince: false,
		headerEditWebsiteDetailsTab: 'scheme_website',
		headerFinancialDetailsTab: true,
		headerShowManagement: false,
		headerShowQuickLinks: false,
		headerQuickLinksDashboardUrl: '/dashboard',
		dashboardUrl: '/dashboard',
		headerShowTaskNotifications: false,
		managementTeamSchemeExecutiveIcon: 'SX',
		managementTeamSchemeExecutiveText: 'Scheme Executive',
		managementTeamExecutiveManagingAgentIcon: 'EM',
		managementTeamExecutiveManagingAgentText: 'Executive Managing Agent',
		userEditAssociatedProperties: true,
		userEditAssociatedPlayers: false,
		showFFPropertyExtraFields: false,
		showSponsorExtraAttorneyFields: false,
		serviceProvidersNewsletterAdvertiser: false,
		newsletterSendRequest: 'sendNewsletters',
		newsletterSendTestRequest: 'sendTestNewsletters',
		entityAddEmailOcc: false,
		multipleAlternates: false,
		managementName: 'Management',
		faqUrl: 'https://www.help.amiti.online',
	},
	buildDate: '2025-02-11',
	gitId: '5b10929',
};
